// useManageSubscription.ts
import { ref } from 'vue'
import axios from 'axios'
import useAuth from '/@src/composables/useAuth'
import { useUserStore } from '/@src/stores/userStore'
import { useToast } from 'vue-toastification'

export function useManageSubscription() {
  const subscriptionLink = ref('')
  const { getAccessTokenSilently, isAuthenticated } = useAuth()
  const userStore = useUserStore()
  const toast = useToast()

  const manageSubscription = async () => {
    if (!isAuthenticated) {
      toast.error('You must be logged in to manage your subscription.')
      return
    }

    try {
      const token = await getAccessTokenSilently()
      const headers = {
        Authorization: `Bearer ${token}`,
      }

      const response = await axios.post(
        '/create-checkout-session',
        {},
        { headers },
      )

      if (response.data.checkout_url) {
        subscriptionLink.value = response.data.checkout_url
      } else if (response.data.url) {
        subscriptionLink.value = response.data.url
      } else {
        throw new Error('No valid URL received from the server')
      }

      await userStore.checkSubscription(token)
    } catch (error: any) {
      console.error('Failed to manage subscription:', error)
      toast.error(
        error.response?.data?.message ||
          'Something went wrong, please try again.',
      )
    }
  }

  return { subscriptionLink, manageSubscription }
}
