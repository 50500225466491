// src/composables/useAuth.ts
import { computed, ref } from 'vue'
import { useAuth0 } from '@auth0/auth0-vue'
import { useUserStore } from '/@src/stores/userStore'
import axios from 'axios'
import { useRoute } from 'vue-router'

export default function useAuth() {
  const auth0 = useAuth0()
  const userStore = useUserStore()
  const isSubscriptionChecked = ref(false)
  const isAuthenticatedLocally = ref(false)
  const route = useRoute()

  if (!auth0 || !auth0.getAccessTokenSilently) {
    throw new Error('Auth0 is not properly initialized.')
  }

  const { getAccessTokenSilently } = auth0

  const syncUserWithServer = async () => {
    try {
      const accessToken = await getAccessTokenSilently()
      const idTokenClaims = auth0.idTokenClaims.value

      if (!accessToken || !idTokenClaims) return

      const idToken = idTokenClaims.__raw

      const response = await axios.post(
        '/auth/sync',
        { id_token: idToken },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        },
      )

      userStore.setUser(response.data.user)
      if (response.data.subscription) {
        userStore.setSubscriptionStatus(response.data.subscription)
      }
      isAuthenticatedLocally.value = true
    } catch (error) {
      console.error('Error syncing user:', error)
      isAuthenticatedLocally.value = false
    }
  }

  const checkSubscription = async () => {
    try {
      const token = await getAccessTokenSilently()
      if (!token) return false

      const result = await userStore.checkSubscription(token)
      isSubscriptionChecked.value = true
      return result
    } catch (error) {
      console.error('Error checking subscription:', error)
      return false
    }
  }

  return {
    login: (returnTo = route.fullPath) => {
      auth0.loginWithRedirect({
        appState: { target: returnTo },
      })
    },
    signup: (returnTo = route.fullPath) => {
      auth0.loginWithRedirect({
        authorizationParams: {
          screen_hint: 'signup',
        },
        appState: { target: returnTo },
      })
    },
    logout: () =>
      auth0.logout({
        logoutParams: {
          returnTo: window.location.origin,
        },
      }),
    syncUserWithServer,
    isAuthenticated: computed(() => auth0.isAuthenticated.value),
    user: computed(() => auth0.user.value),
    isLoading: computed(() => auth0.isLoading.value),
    isSubscribed: computed(() => userStore.isSubscribed),
    isSubscriptionChecked,
    checkSubscription,
    getAccessTokenSilently: auth0.getAccessTokenSilently,
    loginWithPopup: auth0.loginWithPopup,
  }
}
