<script setup>
import { ref, onMounted, onUnmounted } from 'vue'

const props = defineProps({
  poweredByImageSrc: {
    type: String,
    default: '/assets/illustrations/gpt_logo.svg',
  },
  poweredByText: {
    type: String,
    default: 'Powered by GPT-4o',
  },
  titleText: {
    type: String,
    default:
      '<span class="text-primary">Yield powerful insights </span><div>with in-depth interviews</div>',
  },
  subtitleText: {
    type: String,
    default: 'Create AI agents instantly for all your customer research needs.',
  },
  primaryButtonText: {
    type: String,
    default: 'Start for free',
  },
  primaryButtonLink: {
    type: String,
    default: '/app/create',
  },
  secondaryButtonText: {
    type: String,
    default: 'Watch Demo',
  },
  secondaryButtonLink: {
    type: String,
    default: 'https://youtu.be/5KAIqgP0H-Y',
  },
})


const windowWidth = ref(window.innerWidth)

function updateWindowWidth() {
  windowWidth.value = window.innerWidth
}

onMounted(() => {
  window.addEventListener('resize', updateWindowWidth)
})

onUnmounted(() => {
  window.removeEventListener('resize', updateWindowWidth)
})

</script>

<template>
  <Hero
    alignment="center"
    size="fullheight"
  >
    <template #body>
      <div class="columns is-vcentered mt-12">
        <!--Hero Content-->
        <div class="column is-relative">
          <div class="mx-auto max-w-6 mb-4">
            <div class="powered-by">
              <img
                :src="poweredByImageSrc"
                alt="Powered by"
                width="12"
                height="12"
              >
              <p class="paragraph">
                {{ poweredByText }}
              </p>
            </div>

            <Title
              tag="h1"
              :size="1"
              class="font-alt"
              v-html="titleText"
            />
            <Subtitle
              tag="p"
              :size="5"
              weight="thin"
              class="mx-auto max-w-6 b-centered-tablet-p pt-2"
            >
              {{ subtitleText }}
            </Subtitle>
            <!--Buttons-->
            <div class="buttons is-relative z-1">
              <Button
                color="primary"
                :long="3"
                bold
                raised
                :to="primaryButtonLink"
              >
                {{ primaryButtonText }}
              </Button>
              <Button
                :long="3"
                bold
                elevated
                outlined
                target="_blank"
                :href="secondaryButtonLink"
              >
                {{ secondaryButtonText }}
              </Button>
            </div>
          </div>

          <!--Hero App Mockup-->
          <div class="centered-app-wrapper mx-auto max-w-8 mt-6" />
          <SolutionsPreviews />
        </div>
      </div>
    </template>
  </Hero>
</template>

<style lang="scss" scoped>
:deep(.hero) {
  overflow: hidden !important;
}

:deep(.hero-body) {
  padding-top: 0 !important;
}

.centered-app-wrapper {
  position: relative;

  .centered-app:deep(img) {
    position: relative;
    display: block;
    border-radius: 1rem;
    border: 1px solid var(--border);
    box-shadow: var(--light-box-shadow);
    z-index: 1;
  }

  .centered-app-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    background: var(--card-bg-color);
    opacity: 0;
    transition: opacity 0.3s;
    z-index: 2;
  }

  .centered-app-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.85);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.35rem;
    width: 58px;
    height: 58px;
    border-radius: 50%;
    z-index: 3;
    cursor: pointer;
    border: none;
    background: var(--info);
    box-shadow: var(--info-box-shadow);
    transition: transform 0.3s;
    animation: pulse 2s infinite;

    .iconify {
      position: relative;
      right: -0.15rem;
      vertical-align: middle;
      color: var(--white);

      :deep(path) {
        fill: var(--white);
      }
    }
  }

  .centered-app-face {
    position: absolute;
    z-index: 0;
    border-radius: none;

    img {
      display: block;
      max-width: 100%;
      object-fit: cover;
    }

    &.face-1 {
      right: -240px;
      top: -80px;
      width: 380px;
    }

    &.face-2 {
      left: -100px;
      top: -120px;
      width: 140px;
      animation: floatUpDown 4s ease-in-out infinite;
    }

    @keyframes floatUpDown {
      0% {
        transform: translateY(0);
      }

      50% {
        transform: translateY(-10px);
      }

      100% {
        transform: translateY(0);
      }
    }

    &.face-3 {
      right: -140px;
      bottom: 120px;
      width: 100px;
      animation: floatUpDown 10s ease-in-out infinite;
    }

    &.face-4 {
      left: -180px;
      bottom: 40px;
      width: 400px;
    }
  }

  &:hover {
    .centered-app-overlay {
      opacity: 0.7;
    }

    .centered-app-button {
      transform: translate(-50%, -50%) scale(1);
    }
  }
}

.video-container {
  position: relative;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.video-fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: var(--card-bg-color);

  iframe {
    width: 100%;
    height: 100%;
  }
}

.video-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10000;
  background: transparent;
  border: none;
  font-size: 2rem;
  color: var(--title-color);
  cursor: pointer;
}

@keyframes pulse {
  0% {
    transform: translate(-50%, -50%) scale(0.85);
  }

  50% {
    transform: translate(-50%, -50%) scale(1);
  }

  100% {
    transform: translate(-50%, -50%) scale(0.85);
  }
}

.is-dark {
  .centered-app-wrapper {
    .centered-app:deep(img) {
      border: none;
    }
  }
}

@media only screen and (max-width: 767px) {
  .font-alt {
    font-size: 1.5rem;
  }

  .centered-app-wrapper {
    .centered-app-face {
      &.face-1 {
        right: 25%;
        top: -25px;
        width: 285px;
      }

      &.face-2,
      &.face-3,
      &.face-4,
      &.face-5 {
        display: none;
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .centered-app-wrapper {
    .centered-app-face {
      &.face-1 {
        right: -8px;
        top: 325px;
        z-index: 3;
        width: 318px;
      }

      &.face-2 {
        left: -40px;
        top: -100px;
        width: 318px;
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .centered-app-wrapper {
    .centered-app-face {
      &.face-1 {
        right: -60px;
        top: 90px;
        width: 118px;
      }

      &.face-2 {
        left: -80px;
        top: -100px;
        width: 118px;
      }

      &.face-3 {
        bottom: 40px;
      }

      &.face-4 {
        left: -75px;
        width: 100px;
      }
    }
  }
}

.font-alt {
  font-family: var(--font-extra);
}

.powered-by {
  justify-content: center;
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  color: var(--light-text);
  padding-bottom: 0.5rem;

  img {
    margin-right: 0.5rem;
  }
}
</style>
