<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { Carousel, Slide, Navigation } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'

interface Solution {
  slug: string
  baseAssistantTask: string
  cover: string
  heroL: {
    tags: { text: string; color: string }[]
    title: string
    subtitle: string
  }
}

const solutions = ref<Solution[]>([])
const isLoading = ref(true)
const error = ref<string | null>(null)

const carouselBreakpoints = {
  768: {
    itemsToShow: 2,
    snapAlign: 'center',
  },
  1024: {
    itemsToShow: 3,
    snapAlign: 'center',
  },
}

onMounted(async () => {
  try {
    const context = import.meta.glob('/public/data/solutions/*.json', { eager: true })
    solutions.value = Object.values(context).map((module: any) => module.default)
    console.log('Loaded solutions:', solutions.value)
    isLoading.value = false
  } catch (err) {
    console.error('Error loading solution files:', err)
    error.value = err instanceof Error ? err.message : 'An unknown error occurred'
    isLoading.value = false
  }
})
</script>

<template>
  <div v-if="isLoading">
    Loading solutions...
  </div>
  <div v-else-if="error">
    Error: {{ error }}
  </div>
  <div
    v-else-if="solutions.length > 0"
    class="solutions-carousel"
  >
    <Carousel
      :items-to-show="1"
      :wrap-around="true"
      :breakpoints="carouselBreakpoints"
    >
      <Slide
        v-for="solution in solutions"
        :key="solution.slug"
      >
        <div
          class="carousel__item"
          :style="{ backgroundImage: `url(${solution.cover})` }"
        >
          <RouterLink
            :to="{ path: `/solutions/${solution.slug}` }"
          >
            <div class="card-content">
              <div class="tags">
                <Tag
                  v-for="tag in solution.heroL.tags"
                  :key="tag.text"
                  color="solid"
                  :label="tag.text"
                  class="mr-2"
                />
              </div>
              <div class="title-container">
                <Subtitle
                  :size="6"
                  class="card-subtitle p-4"
                >
                  {{ solution.heroL.subtitle }}
                </Subtitle>
                <Title
                  :size="6"
                  weight="bold"
                  class="card-title mb-4 pt-4"
                >
                  {{ solution.baseAssistantTask }}
                </Title>
              </div>
            </div>
          </RouterLink>
        </div>
      </Slide>

      <template #addons>
        <Navigation class="arrows" />
        <!-- <Pagination /> -->
      </template>
    </Carousel>
  </div>
  <div v-else>
    No solutions found.
  </div>
</template>

<style lang="scss" scoped>
.carousel__item {
  min-height: 400px;
  width: 100%;
  font-size: 20px;
  border-radius: 8px;
  display: flex;
  background: var(--card-border-color);
  background-size: cover;
  background-position: center;
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease;
  margin-right: 1rem; // Add some space between items

  &:hover {
    transform: scale(1.05);

    .card-subtitle {
      opacity: 1;
    }
  }
}

:deep(.carousel__slide) {
  padding: 1rem; // Reduce padding to allow for partial next slide
}

// Add responsive styles
@media (max-width: 767px) {
  .carousel__item {
    min-height: 300px; // Adjust height for mobile if needed
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .carousel__item {
    min-height: 350px; // Adjust height for tablet if needed
  }
}

.card-content {
  position: absolute;
  inset: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
}

.tags {
  display: flex;
  flex-wrap: wrap;
}

.title-container {
  text-align: left;
}

.card-title,
.card-subtitle {
  color: var(--title-color);
}

.card-subtitle {
  opacity: 0;
  border-left: 1px solid var(--title-color);
  transition: opacity 0.3s ease;
}

:deep(.carousel__slide) {
  padding: 2rem;
}



:deep(.carousel__pagination) {
  padding-top: 1rem;
}

:deep(.carousel__pagination-button) {
  background-color: transparent;

  &.carousel__pagination-button--active {
    background-color: var(--primary);
  }
}

.carousel-container {
  position: relative;
}

:deep(.carousel__prev),
:deep(.carousel__next) {
  color: var(--title-color);

  &:hover {
    color: var(--primary);
  }
}

:deep(.carousel__prev) {
  left: -2rem;
}

:deep(.carousel__next) {
  right: -2rem;
}
</style>